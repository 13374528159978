import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './Header.module.css';
import useMedia from '../hooks/useMedia';

const Header = () => {

  const [menuMobile, setMenuMobile] = React.useState(false);
  const mobile = useMedia('(max-width:40rem)');

  const {pathname} = useLocation();

  React.useEffect(()=>{
    setMenuMobile(false);
  }, [pathname]);

  const menuItems = document.querySelectorAll('nav li a[href^="#');
  
  menuItems.forEach(item => {
    item.addEventListener('click', scrollToIdOnClick);
  });

  function scrollToIdOnClick(event){
    event.preventDefault();
    const element = event.target;
    const id = element.getAttribute('href');
    const to = document.querySelector(id).offsetTop;
    window.scroll({
      top: to - 64,
      behavior: "smooth",
    })

  }

  return (
    <header className={styles.header}>
      <nav className={`${styles.nav} container`}>
        <NavLink className={styles.logo} to="/" aria-label="New Tech">
            <img src="/assets/logo.jpg" alt="logo" />
        </NavLink>
        {mobile &&
          <button 
            aria-label="Menu" 
            className={`${styles.mobileButton} ${menuMobile && styles.mobileButtonActive}`}
            onClick={()=>setMenuMobile(!menuMobile)}
          > 
          </button>
        }
        <div className={`${mobile ? styles.navMobile : styles.nav} ${menuMobile && styles.navMobileActive}`}>
          <ul>
            <li><a href="#home" >INÍCIO</a></li>
            <li><a href="#obras">OBRAS</a></li>
            <li><a href="#contato" >CONTATO</a></li>
            <button className={styles.botao}>Orçamento</button>
          </ul>
        </div>

        
      </nav>
    </header>
  )
}

export default Header;
