import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Atributos from './components/Atributos';
import Obras from './components/Obras';
import Contato from './components/Contato';
import Footer from './components/Footer';


function App() {
  
  return (
    <BrowserRouter>
      <div>
        <Header />
        <Home />
        <Atributos />
        <div className="App container">
          <Obras />
        </div>
        <Contato />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
