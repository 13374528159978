import React from 'react';
import styles from "./Obras.module.css";

const Obras = () => {
  return (
    <section id="obras" >
      <h1>Obras Concluídas</h1>
      <div className={`${styles.content} container`}>
        <div className={styles.box}>
        <div className={styles.image}>
            <img src="/assets/box1.jpg" alt="Serviços" />
          </div>
          <div className={styles.texto}>
            <h2>Condomínio Sol Nascente</h2>
            <p>Sistema 7.2kWp.</p>
            <p>Economia: R$ 1.200,00/mês</p>
            <p>Concluída: 20/05/2020</p>
          </div>
        </div>
        <div className={styles.box}>
        <div className={styles.image}>
            <img src="/assets/box1.jpg" alt="Serviços" />
          </div>
          <div className={styles.texto}>
            <h2>Condomínio Sol Nascente</h2>
            <p>Sistema 7.2kWp.</p>
            <p>Economia: R$ 1.200,00/mês</p>
            <p>Concluída: 20/05/2020</p>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.image}>
            <img src="/assets/box1.jpg" alt="Serviços" />
          </div>
          <div className={styles.texto}>
            <h2>Condomínio Sol Nascente</h2>
            <p>Sistema 7.2kWp.</p>
            <p>Economia: R$ 1.200,00/mês</p>
            <p>Concluída: 20/05/2020</p>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.image}>
            <img src="/assets/box1.jpg" alt="Serviços" />
          </div>
          <div className={styles.texto}>
            <h2>Condomínio Sol Nascente</h2>
            <p>Sistema 7.2kWp.</p>
            <p>Economia: R$ 1.200,00/mês</p>
            <p>Concluída: 20/05/2020</p>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.image}>
            <img src="/assets/box1.jpg" alt="Serviços" />
          </div>
          <div className={styles.texto}>
            <h2>Condomínio Sol Nascente</h2>
            <p>Sistema 7.2kWp.</p>
            <p>Economia: R$ 1.200,00/mês</p>
            <p>Concluída: 20/05/2020</p>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.image}>
            <img src="/assets/box1.jpg" alt="Serviços" />
          </div>
          <div className={styles.texto}>
            <h2>Condomínio Sol Nascente</h2>
            <p>Sistema 7.2kWp.</p>
            <p>Economia: R$ 1.200,00/mês</p>
            <p>Concluída: 20/05/2020</p>
          </div>
        </div>
      </div>{/*content */}
    </section>
  )
}

export default Obras;
