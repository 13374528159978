import React from 'react';
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const Slide = () => {

  const images = [
    'assets/slide1.jpg',
    'assets/slide2.jpg',
    'assets/slide3.jpg'
  ];
  
  const zoomInProperties = {
    indicators: false,
    scale: 1.4
  }

  return (
    <>
      <Zoom {...zoomInProperties}>
        { images.map((each, index) => (
          <div className="slide">
            <img key={index} style={{width: "100%"}} src={each} />
          </div>
        ))}
      </Zoom>
    </>
  )
}
export default Slide;
