import React from 'react';
import styles from './Home.module.css';
import Slide from './Slide';

const Home = () => {
  return (
    <section id="home" className={styles.home}>
        <Slide />
    </section>
  )
}

export default Home;
