import React from 'react';
import styles from './Atributos.module.css';

//svg
import { ReactComponent as Sustentavel } from '../assets/dom.svg';
import { ReactComponent as Economia } from '../assets/money.svg';
import { ReactComponent as Valoriza } from '../assets/casa.svg';
import { ReactComponent as Ambiente } from '../assets/planeta-terra.svg';

const Atributos = () => {
  return (
    <>
    <div className={styles.atributos}>
      <div className="container">
        <div>
          <span className={styles.svg}><Sustentavel /></span>  
          <span className={styles.texto}>Auto Sustentável</span>
        </div>
        <div>
          <span className={styles.svg}><Economia /></span>
          <span className={styles.texto}>Econômia de até 95%</span>
        </div>
        <div>
          <span className={styles.svg}><Valoriza /></span>
          <span className={styles.texto}>Valorização do Imóvel</span>
        </div>
        <div>
          <span className={styles.svg}><Ambiente /></span>
          <span className={styles.texto}>Não Polui o Ambiente</span>
        </div>
      </div>
    </div>
    <div className={styles.bg}>
      <div className={`${styles.content} container`}>
          <div className={styles.box}>
            <div className={styles.texto}>
              <h2>Tecnologias</h2>
              <p>Trabalhamos em busca da melhor solução para o cliente, com foco em manter excelência em primeiro lugar.</p>
            </div>
            <div className={styles.image}>
              <img src="/assets/box1.jpg" alt="Serviços" />
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.texto}>
              <h2>Projetos</h2>
              <p>Elaboração de projetos complementares, laudos técnicos, estudos para eficiência energética e manutenções.</p>
            </div>
            <div className={styles.image}>
              <img src="/assets/box2.jpg" alt="Serviços" />
            </div>
          </div>
        </div>{/*content */}
      </div>
    
    </>
  )
}

export default Atributos;
