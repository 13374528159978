import React from 'react';
import styles from './Contato.module.css';
import Input from './Form/Input';
import Button from './Form/Button';
import useForm from '../hooks/useForm';


const Contato = () => {
  return (
    <section id="contato" className={styles.bg}>
      <h1>Contato</h1>
      <div className={`${styles.contato} container`}>
        <div className={styles.form}>
          <form>
            <Input label="Nome" type="text" placeholder="Informe seu nome" />
            <Input label="Email" type="email" placeholder="Digite um email" />
            <Input label="Telefone" type="text" placeholder="Tefone para contato" />
            <textarea
              id="comment"
              className={styles.textarea} 
              placeholder="Mensagem"
            />
            <Button>Enviar</Button>
          </form>
        </div>
        <div className={styles.image}>
          <img src="/assets/contact.jpg" alt="contato" />
        </div>
      </div>
    </section>
  )
}

export default Contato;
