import React from 'react';
import styles from './Footer.module.css';
import { ReactComponent as Pin } from '../assets/pin.svg';
import { ReactComponent as Insta } from '../assets/instagram.svg';
import { ReactComponent as Face } from '../assets/facebook.svg';

const Footer = () => {
  return (
    <footer className={`${styles.footer}`} >
      <div className={`${styles.info} container`}>
          <div className={styles.image}>
            <img src="/assets/logo2.png" alt="logo" />
          </div>
          <div className={styles.address}>
            <div><Pin /></div>
            <div>
              <p>Avenida Brasil, 152</p>
              <p>Centro - São Paulo</p>
            </div>
          </div>
          <div className={styles.social}>
            <a href="#" className={styles.socialDiv}>
              <a></a>
              <div>
                <Face /> 
              </div>
              <p>Facebook</p>
            </a>
            <a href="#" className={styles.socialDiv}>
              <div>
                <Insta /> 
              </div>
              <p>Instagram</p>
            </a>
          </div>
      </div>
      <div className={styles.create}>
        <p>Desenvolvido por <span><a href="http://www.f3softwares.com.br" target="_blank" >F3Software</a></span></p>
      </div>
    </footer>
      
  )
}

export default Footer;
